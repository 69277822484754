import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css';
import axios from "axios";
import VueRouter from "vue-router";
import router from "./router";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

Vue.prototype.$axios=axios;

Vue.prototype.$httpUrl='https://www.t5pouy.top/api/';

//http://localhost:8081
// https://www.t5pouy.top/api/
//
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(ElementUI, {size:'small'});
new Vue({
  router,
  components: { App },
  template: '<App/>',
  render: h => h(App),
}).$mount('#app')



