<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import router from './router'
export default {
  name: 'App',
  router,
  components: {

  },
}
</script>

<style>

#app {
  height: 100%;
}
</style>
